import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "form-leads",
                href: "/tabs/form-leads"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.personAddOutline }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Registrar Leads")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "sync-leads",
                href: "/tabs/sync-leads"
              }, {
                default: _withCtx(() => [
                  ($options.pendingAmount > 0)
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 0,
                        color: "danger"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($options.pendingAmount), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  ($options.pendingAmount > 0)
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 1,
                        icon: $setup.syncOutline
                      }, null, 8, ["icon"]))
                    : (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 2,
                        icon: $setup.checkmarkDoneCircleOutline,
                        color: "success"
                      }, null, 8, ["icon"])),
                  _createVNode(_component_ion_label, {
                    textContent: _toDisplayString($options.pendingAmount > 0 ? 'Sincronizar' : 'Sincronizado')
                  }, null, 8, ["textContent"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}