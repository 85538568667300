import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import { API, setApiAuthorizationToken } from '../inc/api'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default createStore({
  state: {
    loginToken: null,
    lugaresContacto: [] as Array<any>,
    paises: [] as Array<any>,
    categorias: [] as Array<any>,
    leadsAwaitingInsertionQueue: [] as Array<any>
  },
  mutations: {
    LOGIN_TOKEN(state, token) {
      state.loginToken = token
    },
    ENQUEUE_LEAD(state, leadObj) {
      state.leadsAwaitingInsertionQueue.push(leadObj)
    },
    SHIFT_LEAD(state) {
      state.leadsAwaitingInsertionQueue.shift();
    },
    ITEM_STARTED_SYNC(state, index) {
      state.leadsAwaitingInsertionQueue[index]._syncing = true;
    },
    ITEM_STOPED_SYNC(state, index) {
      state.leadsAwaitingInsertionQueue[index]._syncing = false;
    },
    SET_LUGARES_CONTACTO(state, arr) {
      state.lugaresContacto = arr;
    },
    SET_PAISES(state, arr) {
      state.paises = arr
    },
    SET_CATEGORIAS(state, arr) {
      state.categorias = arr
    }
  },
  actions: {
    async logIn(cx, credentials: { email: string; password: string }) {
      const response: any = await API.post('/login', {
        email: credentials.email,
        password: credentials.password
      });

      const success = response.success && typeof response.data !== 'undefined' && typeof response.data.token === 'string'

      if (success) {
        cx.commit('LOGIN_TOKEN', response.data.token)
        setApiAuthorizationToken(response.data.token);
        return true;
      } else {
        throw new Error(response.message || 'Ocurrio un error')
      }
    },
    async insertLead(cx, lead) {
      let response: any;

      if (window.navigator.onLine) {
        response = await API.post('/register_lead', lead);
      } else {
        response = {
          success: false
        }
      }

      if (response.success === true) {
        cx.commit('SHIFT_LEAD');
        return true;
      } else {
        lead.created = Math.floor(Date.now() / 1000);
        lead._syncing = false;
        cx.commit('ENQUEUE_LEAD', lead);
        return false;
      }

    },
    async insertNextLeadInQueue(cx) {
      if (!window.navigator.onLine) {
        return false;
      }
      if (cx.state.leadsAwaitingInsertionQueue.length === 0) {
        return false;
      }

      const nextLead = cx.state.leadsAwaitingInsertionQueue[0];

      cx.commit('ITEM_STARTED_SYNC', 0);

      const response: any = await API.post('/register_lead', nextLead);

      if (response.success === true) {
        cx.commit('SHIFT_LEAD')
        return true;
      }

      cx.commit('ITEM_STOPED_SYNC', 0);
      throw new Error();
    },
    async fetchFormData(cx) {
      if (!window.navigator.onLine) {
        return false;
      }

      try {
        const formData: any = await API.get('/get_form_data');

        if (formData.success !== true) {
          return false;
        }

        cx.commit('SET_LUGARES_CONTACTO', formData.data.lugares_contacto)
        cx.commit('SET_PAISES', formData.data.paises)
        cx.commit('SET_CATEGORIAS', formData.data.categorias)

        return true;
      } catch (e) {
        return false;
      }
    }
  },
  getters: {
    countPendingLeads(state) {
      return state.leadsAwaitingInsertionQueue.length
    },
    isLoggedIn(state) {
      return typeof state.loginToken === 'string'
    }
  },
  plugins: [
    vuexLocal.plugin
  ]
})
