<template>
<ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Iniciar sesión</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Iniciar sesión</ion-title>
        </ion-toolbar>
      </ion-header>
      <form @submit.prevent="handleSubmit">
        <ion-card>
          <ion-card-header>
            <ion-card-title>Ingresa tus credenciales</ion-card-title>
          </ion-card-header>

          <ion-card-content>
          
            <ion-item>
              <ion-label position="stacked" :color="email.valid ? 'default' : 'danger'">Correo Electrónico</ion-label>
              <ion-input required v-model="email.value" :disabled="loading" @input="email.valid = true" type="email" placeholder="Correo electrónico"></ion-input>
            </ion-item>

            <ion-item>
              <ion-label position="stacked" :color="password.valid ? 'default' : 'danger'">Contraseña</ion-label>
              <ion-input required v-model="password.value" :disabled="loading" @input="password.valid = true" type="password" placeholder="Tu contraseña"></ion-input>
            </ion-item>
          
            <hr>
          
            <ion-button type="submit" expand="block" :disabled="loading">
              <ion-spinner v-if="loading" name="crescent"></ion-spinner>
              <span v-else>Ingresar</span>
            </ion-button>
          </ion-card-content>
        </ion-card>
      </form>


    </ion-content>
  </ion-page>

</template>

<script>
import { IonButton, IonContent, IonPage, IonInput, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonHeader, IonItem, IonLabel, IonSpinner, IonTitle, IonToolbar, toastController } from '@ionic/vue';

export default {
  components: { IonButton, IonContent, IonPage, IonInput, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonHeader, IonItem, IonLabel, IonSpinner, IonTitle, IonToolbar },
  data () {
    return {
      loading: false,
      email: {
        value: '',
        valid: true
      },
      password: {
        value: '',
        valid: true
      }
    }
  },
  methods: {
    
    async showToast (msj, t = 500) {
      (await toastController
        .create({
          message: msj,
          duration: t
        })).present();
    },
    checkInputsValidity () {
      let allValid = true;

      if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email.value)) {
        allValid = false;
        this.email.valid = false;
      }

      if (this.password.value.length < 3) {
        allValid = false;
        this.password.valid = false;
      }

      return allValid;
    },
    async handleSubmit () {
      this.loading = true;

      if (!this.checkInputsValidity()) {
        this.showToast('Revisa los campos marcados');
        this.loading = false;
        return;
      }

      try {
        await this.$store.dispatch('logIn', {
          email: this.email.value,
          password: this.password.value
        });

        this.showToast('Login exitoso', 1200);
        this.$router.push({path: '/tabs'});
      } catch (error) {
        console.error(error);
        this.showToast(error.message);
        this.loading = false;
      }
      
    }
  }
}
</script>

<style>

</style>