import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue'
import Login from '../views/Login.vue'
import store from '../store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: () => {
      if (store.state.loginToken) {
        return '/tabs/form-leads'
      }

      return '/login'
    }
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/form-leads'
      },
      {
        path: 'form-leads',
        component: () => import('@/views/FormLeads.vue')
      },
      {
        path: 'sync-leads',
        component: () => import('@/views/SyncLeads.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
