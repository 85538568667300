import axios from "axios";

const API = axios.create({
  headers: {
    'X-Source-App': 'PulpaLeads-Fernandez',
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: process.env.VUE_APP_API_TIMEOUT,
  baseURL: process.env.VUE_APP_API_BASEURL
});

API.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  if (
    error.code === 'ECONNABORTED' ||
    !error.response ||
    error.response.status === 408 ||
    !error.response.data
  ) {
    return Promise.resolve({
      success: false,
      message: 'No stable internet connection'
    });
  }
  
  return Promise.resolve(error.response.data);
});

const setApiAuthorizationToken = function setApiAuthorizationToken (token: string) {
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export {
  API,
  setApiAuthorizationToken
}