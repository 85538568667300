
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet, IonBadge } from '@ionic/vue';
import { personAddOutline, syncOutline, checkmarkDoneCircleOutline } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet, IonBadge },
  setup() {
    return {
      personAddOutline,
      syncOutline,
      checkmarkDoneCircleOutline
    }
  },
  computed: {
    pendingAmount () {
      return (this as any).$store.getters.countPendingLeads
    }
  }
}
