
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { setApiAuthorizationToken } from './inc/api'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  data () {
    return {
      status: window.navigator.onLine ? 'online' : 'offline',
      syncing: false
    }
  },
  methods: {
    handleOnlineStatus () {
      this.status = window.navigator.onLine ? 'online' : 'offline';
    },
    async syncQueue () {
      if (this.syncing) {
        return;
      }

      let continueSync = true;
      this.syncing = true;
      while (continueSync && this.status === 'online' && this.pendingLeads > 0) {
        try {
          continueSync = await (this as any).$store.dispatch('insertNextLeadInQueue')
        } catch (error) {
          continueSync = false;
        }
      }

      this.syncing = false;
    },
    addListeners () {

      window.addEventListener('online',  this.handleOnlineStatus);
      window.addEventListener('offline', this.handleOnlineStatus);
      window.addEventListener('sync', this.syncQueue);

      setApiAuthorizationToken((this as any).$store.state.loginToken);

      (this as any).$store.dispatch('fetchFormData')

      this.syncQueue();
    },
    removeListeners () {

      window.removeEventListener('online',  this.handleOnlineStatus);
      window.removeEventListener('offline', this.handleOnlineStatus);
      window.removeEventListener('sync', this.syncQueue);

      setApiAuthorizationToken((this as any).$store.state.loginToken);
    }
  },
  mounted () {
    if (this.isLoggedIn) {
      this.addListeners()
    }
  },
  computed: {
    pendingLeads () {
      return (this as any).$store.getters.countPendingLeads;
    },
    isLoggedIn () {
      return (this as any).$store.getters.isLoggedIn;
    }
  },
  watch: {
    isLoggedIn (itIs) {
      if (itIs) {
        this.addListeners();
      } else {
        this.removeListeners();
      }
    }
  }
});
